import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { PageContextProps } from '@shared/types/commonTypes';

const GatsbyPageContext = createContext<Partial<PageContextProps>>({});

export const GatsbyPageContextProvider: FC<PropsWithChildren & { pageContext: PageContextProps }> = ({
  children,
  pageContext,
}) => {
  return <GatsbyPageContext.Provider value={{ ...pageContext }}>{children}</GatsbyPageContext.Provider>;
};

export const useGatsbyPageContext = () => {
  const context = useContext(GatsbyPageContext);

  if (!context) {
    throw Error('useGatsbyPageContext must be used within GatsbyPageContext');
  }

  return context;
};
