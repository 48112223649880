import Cookie from 'universal-cookie';

interface SetCookieProps {
  cookieName: string;
  cookieValue: string;
  path?: string;
}

const COOKIE_MAX_AGE = 900000;

export const getCookie = (cookieName: string, defaultCookie = '') => {
  const cookie = new Cookie();
  return cookie.get(cookieName) ?? defaultCookie;
};

export const setCookie = ({ cookieName, cookieValue, path = '/' }: SetCookieProps) => {
  const cookie = new Cookie();

  cookie.set(cookieName, cookieValue, {
    maxAge: COOKIE_MAX_AGE,
    httpOnly: false,
    path,
  });
};
