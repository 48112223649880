import React from 'react';

export const Page404Icon: React.FC<{ className?: string }> = (props) => (
  <svg width={200} height={200} {...props}>
    <title>404</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(13.904 30.525)">
        <path d="M103.365.448H147a2 2 0 012 2V57a2 2 0 01-2 2H97.365a2 2 0 01-2-2V8.448a8 8 0 018-8z" fill="#E0E4E6" />
        <rect
          stroke="#3F4144"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          x={89.486}
          y={0.448}
          width={59.129}
          height={59.129}
          rx={4}
        />
        <circle
          stroke="#3F4144"
          strokeWidth={3}
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={119.05}
          cy={30.012}
          r={18.505}
        />
        <circle fill="#3F4144" cx={110.967} cy={27.849} r={3.245} />
        <circle fill="#3F4144" cx={127.376} cy={27.849} r={3.245} />
        <path
          d="M113.021 37.002c2.01-1.44 4.02-2.16 6.03-2.16 2.01 0 4.019.72 6.029 2.16M1.599 78.155h171.734"
          stroke="#3F4144"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.823 113.166c-6.126-3.106-10.211-1.995-12.256 3.33-1.4 3.65.707 6.784 6.322 9.406"
          stroke="#3F4144"
          strokeWidth={3}
          fill="#7ED987"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.43 140.308c-9.007-4.803-13.906-12.601-11.523-18.963 3.04-8.118 13.639-14.676 29.934-4.617"
          fill="#EDEFF0"
          stroke="#3F4144"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
        />
        <path
          d="M.238 118.873c36.568-9.996 57.188-15.98 61.859-17.95 5.123-2.161-27.528-10.17-30.141 0-.583 2.267 15.473 7.095 48.166 14.484"
          stroke="#3F4144"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.153 123.952c-.01-2.048-.638-3.85-1.938-5.151-3.719-3.719-11.54-1.928-17.468 4-5.928 5.928-7.719 13.749-4 17.468 2.393 2.393 6.485 2.504 10.678.7"
          stroke="#3F4144"
          strokeWidth={3}
          fill="#EDEFF0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115.704 134.592c1.043-.81 1.829-1.692 2.358-2.644"
          stroke="#3F4144"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path fill="#7ED987" d="M117.33 165.873l10.616 6.377 2.76-.54.859-3.142-11.728-7.09-3.052 1.855z" />
      <path
        d="M126.707 171.51c1.12.707 2.136 1.06 3.221.707a2.907 2.907 0 001.867-3.663c-.302-.93-1.24-1.601-2.128-2.24M127.074 171.728l-9.297-5.368m2.96-5.127l9.297 5.368"
        stroke="#3F4144"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(107.102 35.018)" fill="#3F4144">
        <circle cx={2.329} cy={2.807} r={2.329} />
        <circle cx={48.123} cy={2.807} r={2.329} />
      </g>
      <g transform="translate(107.102 80.238)" fill="#3F4144">
        <circle cx={2.329} cy={2.807} r={2.329} />
        <circle cx={48.123} cy={2.807} r={2.329} />
      </g>
      <path fill="#7ED987" d="M128.278 158.438l10.42 5.696 2.244-1.01.582-2.967-10.441-6.459-2.805.776-.425 1.776z" />
      <path
        d="M120.38 161.031c-1.472-.177-2.587.273-3.346 1.35-.76 1.077-.643 2.31.35 3.7M131.306 153.578c-1.507-.266-2.637.208-3.39 1.423-1.128 1.822-.093 3.364.915 3.857M137.43 163.851l-8.876-5.124m2.96-5.127l8.876 5.124M136.722 163.52c.99.486 2.257 1.138 3.342.785a2.907 2.907 0 001.867-3.663c-.302-.93-1.056-1.59-1.867-2.126"
        stroke="#3F4144"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
