export enum Molecule {
  HOMEPAGE = 'homepage',
  TOP_BANNER = 'top_banner',
  SEARCH_BOX = 'search_box',
  CAMPAIGN_PAGE = 'campaignpage',
  COUNTRY_PAGE = 'countrypage',
  OFFER_PAGE = 'offerpage',
  COST_OF_A_TRIP = 'cost_of_a_trip_page',
  BEST_TIME_TO_TRAVEL = 'best_time_to_travel_page',
  BEST_SIGHTS = 'best_sights_page',
  TRIP_TYPE_DESTINATION = 'trip_type_destination',
  FLOATING_BANNER = 'floating_banner',
  SEO_MODAL = 'seo_modal',
  SECONDARY_NAVIGATION = 'secondary_navigation',
}

export enum Atom {
  MAIN_NAVIGATION = 'main_navigation',
  HOMEPAGE_FEATURED_CAROUSEL = 'homepage_featured_carousel',
  HOMEPAGE_DESTINATION_CAROUSEL = 'homepage_destination_carousel',
  HOMEPAGE_DESTINATION_CARD = 'homepage_destination_card',
  SEARCH_INPUT_FIELD = 'search_input_field',
  DROPDOWN = 'dropdown',
  BUTTON = 'button',
  HOMEPAGE_TRAVEL_EXPERT = 'homepage_travel_expert',
  HOMEPAGE_SERVICE_INTRO = 'homepage_service_intro',
  HOMEPAGE_CUSTOMER_PRIMARY = 'homepage_customer_primary',
  HOMEPAGE_CUSTOMER_SECONDARY = 'homepage_customer_secondary',
  HOMEPAGE_FREE_PILL = 'homepage_free_pill',
  HOMEPAGE_DESTINATION_PILL = 'homepage_destination_pill',
  LOAD_EEE = 'load_eee',
  MAIN_CTA = 'main_cta',
  BOTTOM_CTA = 'bottom_cta',
  SEO_MODAL = 'seo_modal',
  SEO_MODAL_CTA = 'seo_modal_cta',
  LOAD_MAP = 'load_map',
  MAP = 'map',
}

export enum Action {
  CLICK = 'click',
  INPUT_FOCUS = 'input_focus',
  INPUT_TEXT_ENTERED = 'input_text_entered',
  DROPDOWN_APPEAR = 'dropdown_appear',
  DROPDOWN_SELECTED = 'dropdown_selected',
  SUBMIT_BUTTON = 'submit_button',
  VIEW = 'view',
}

export type TrackingFunctionType = (atom: string, label: string) => void;
