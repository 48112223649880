import i18n from 'i18next';

import { Locale } from '@shared/types/locale';
import { appMeta } from '@shared/utils/appMeta';

const translation =
  process.env.NODE_ENV === 'test'
    ? require('../../../plugins/phraseapp-translation/phraseappTranslation.test.json')
    : require('../../../plugins/phraseapp-translation/phraseappTranslation.autogenerated.json');

const resources = Object.assign(
  {},
  ...Object.keys(Locale).map((key) => {
    return { [Locale[key]]: { translation } };
  })
);

i18n.init({
  resources,
  lng: appMeta.locale,
  react: {
    transKeepBasicHtmlNodesFor: ['span'],
  },
  returnObjects: true,
});

export default i18n;

export const t = (key: string, dynamicValue?: { [key: string]: string | number | any }): any => {
  return i18n.t(key, dynamicValue);
};
