import React from 'react';

import classNames from 'classnames';

import { contentWrapperStyle } from './ContentWrapper.css';

export function ContentWrapper(props) {
  const classes = classNames(contentWrapperStyle, props.className);
  return <div {...props} className={classes} />;
}
