import React from 'react';

import '@tourlane/lui/lib/index.css';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'typeface-montserrat';
import 'typeface-playfair-display';
import 'typeface-source-sans-pro';

import '@shared/styles/tailwind.css';

import { ErrorBoundary } from './src/shared/components/ErrorBoundary';
import { GatsbyPageContextProvider } from './src/shared/components/GatsbyPageContext/GatsbyPageContext.context';
import i18n from './src/shared/utils/translations';
import { setupTourlaneCookies } from './utils/cookies';
import { setUpGTM, setUpSnowplowTracking } from './utils/tracking';

export const disableCorePrefetching = () => true;

let cookiesAreSetUp = false;

const setUpCookies = () => {
  if (!cookiesAreSetUp) {
    setupTourlaneCookies();
    cookiesAreSetUp = true;
  }
};

export const onClientEntry = () => {
  setUpCookies();
  setUpSnowplowTracking();
  setUpGTM();
};

export const onRouteUpdate = () => {
  setUpCookies();
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <ErrorBoundary>
      <GatsbyPageContextProvider pageContext={props.pageContext}>{element}</GatsbyPageContextProvider>
    </ErrorBoundary>
  );
};

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export const shouldUpdateScroll = () => {
  window.history.scrollRestoration = 'manual';

  setTimeout(() => {
    if (location.hash) {
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: 'smooth' });
    }
  }, 0);

  return true;
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
