import { Locale, Market } from '@shared/types/locale';

interface AppMeta {
  market: Market;
  locale: Locale;
}

export const localeByMarket = {
  'tourlane.de': 'de-DE',
  'tourlane.com': 'en-US',
  'tourlane.fr': 'fr-FR',
};

export const appMeta: AppMeta = {
  market: (process.env.GATSBY_MARKET as Market) || Market.DE,
  locale: (localeByMarket[process.env.GATSBY_MARKET || Market.DE] as Locale) || Locale.DE,
};

export const init = (newMeta: any) => {
  Object.keys(newMeta).forEach((key) => {
    appMeta[key] = newMeta[key];
  });
};

const previewSubFolderByMarket = {
  'tourlane.de': 'de',
  'tourlane.fr': 'fr',
  'tourlane.com': 'com',
};

export const getPreviewSubFolder = () =>
  process.env.GATSBY_PREVIEW === 'true' ? `/${previewSubFolderByMarket[appMeta.market]}` : '';

export const getLocaleByMarket = (market: Market) => localeByMarket[market];
