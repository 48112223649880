import Cookies from 'universal-cookie';
import { CookieSetOptions } from 'universal-cookie/cjs/types';
import { v4 as uuid } from 'uuid';

const TOURLANE_DOMAINS = [
  'tourlane.com',
  'tourlane.de',
  'tourlane.fr',
  'tlservers.de',
  'tlservers.com',
  'tlservers.fr',
  'localhost',
];

const ONE_HUNDRED_YEARS_IN_MILLISECONDS = 100 * 365 * 24 * 60 * 60 * 1000;
const TRACKING_COOKIE_TTL = ONE_HUNDRED_YEARS_IN_MILLISECONDS;

const TOURLANE_ID_COOKIE_NAME = 'tourlane_id';
const VISIT_ID_COOKIE_NAME = 'visit_id';

export const tourlaneDomainRegExp = new RegExp(TOURLANE_DOMAINS.join('|'));

export const getDomain = () => {
  const [domain] = window.location.host.match(tourlaneDomainRegExp) || ['localhost'];
  return domain === 'localhost' ? '' : `.${domain}`;
};

export const setupTourlaneCookies = () => {
  const cookies = new Cookies();
  let { [TOURLANE_ID_COOKIE_NAME]: tourlaneId, [VISIT_ID_COOKIE_NAME]: visitId } = cookies.getAll();
  const domain = getDomain();

  const setCookie = (name: string, maxAge?: number) => {
    const id = uuid();

    let cookieOptions: CookieSetOptions = {
      path: '/',
      domain,
    };

    if (maxAge) {
      cookieOptions = { ...cookieOptions, maxAge };
    }

    cookies.set(name, id, cookieOptions);
  };

  // This is a browser identifying cookie
  if (!tourlaneId) {
    setCookie(TOURLANE_ID_COOKIE_NAME, TRACKING_COOKIE_TTL);
  }

  // This is the session cookie
  if (!visitId) {
    setCookie(VISIT_ID_COOKIE_NAME);
  }
};
