import * as React from 'react';
import { Component } from 'react';

import * as Sentry from '@sentry/browser';
import { Copy, Heading, Link } from '@tourlane/lui';

import { ContentWrapper } from '@shared/components/ContentWrapper';
import { Page404Icon } from './Page404Icon';

type StateType = { error?: Error; errorInfo?: React.ErrorInfo };

export class ErrorBoundary extends Component<Record<string, unknown>, StateType> {
  state: StateType = {};

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });

    if (errorInfo) {
      Sentry.configureScope((scope) => Object.entries(errorInfo).forEach(([key, value]) => scope.setExtra(key, value)));
    }

    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;

    if (!error) {
      return <>{this.props.children}</>;
    }

    return (
      <ContentWrapper className="flex flex-col items-center">
        <Page404Icon />
        <Heading size="h2">Ooops!</Heading>
        <Copy className="my-6 lg:my-8" bold size="lg">
          Unfortunately, we have a technical problem, on this page.
        </Copy>
        <Copy size="sm" className="mb-5 lg:mb-6">
          <code>{error.message}</code>
        </Copy>
        <Copy className="mb-24">
          {'We are working on fixing this error. Please '}
          <Link onClick={window.location.reload}>try again</Link>
          {' in a few minutes.'}
        </Copy>
      </ContentWrapper>
    );
  }
}
